if (!window.console){ window.console = { log:function(msg){ }}; }
// @codekit-prepend jquery.colorbox-min.js;
// @codekit-prepend jquery.easing.1.3.js;
// @codekit-prepend ppt.jquery.utility.js;

import './_calendar.js';

//新規ウィンドウをオープンさせる
$.fn.blankWindow　=　function(className){
	var className = className || 'externalLink';
	$(this).addClass(className).on('click',function(){
		window.open(this.href, '_blank');
		return false;
	});
}

$(function(){

	$.support.transform  = typeof $("body").css("transform") === "string";
	$.support.transition = typeof $("body").css("transitionProperty") === "string";

	var page = {
		id 		: document.getElementsByTagName('body')[0].getAttribute('id'),
		classes	: false//document.getElementsByTagName('body')[0].getAttribute('class').split(' ')
	};

	//toTop
	//$('#toTop>a').toTop();

	//ページ内スクロール
	$('#sub a[href^=#],#container-header a[href^=#]').click(function(){
		$('html, body').animate({
				'scrollTop': $($(this).attr('href')).offset().top
			}, 600, 'easeInOutExpo');
		return false;
	});

	//禁則処理
	$('#container-footer').each(function(index, el) {
		var yakumonoHead = new RegExp('([「『（【［｛〈《〔])','g');
		var yakumonoFoot = new RegExp('([」』）】］｝〉》〕。、，])','g');
		//var kanaHead = new RegExp('(ト)','g');
		var html = $(this).html();
		html = html.replace(yakumonoHead,'<span class="yakumonoHead">$1</span>');
		html = html.replace(yakumonoFoot,'<span class="yakumonoFoot">$1</span>');
		//html = html.replace(kanaHead,'<span class="kanaHead">$1</span>');
		$(this).html(html);
	});

	//メニュー開閉
	$('#container-header-nav').each(function(index, element) {
		var $target = $(this);
		$(this).on('click',function(event){
			if($target.hasClass('open')){
				$target.removeClass('open');
			}else{
				$target.addClass('open');
				event.stopPropagation()
			}
		});
	});
	$('body').on('click',function(event){
		if($('#container-header-nav').hasClass('open')){
			$('#container-header-nav').removeClass('open');
		}
	});

	//pjax
    if ($.support.pjax) {
        $(document)
            .on('click', 'a[class!="externalLink"]', function(event) {
                event.preventDefault();
                var href = $(this).attr('href');
                // フェードアウトエフェクトの処理
                $('main').animate({
                    opacity : 0
                }, 'fast', function() {
                    // コールバックでpjaxを実行する
                    $.pjax({
                        url: href,
                        container : 'main',
                        fragment : 'main',
                        timeout : 10000
                    });
                });
            })
            .on('pjax:complete', function() {
				var location = window.location.pathname + window.location.search;
				ga('send', 'pageview', location);
            })
            .on('pjax:end', function() {
	page.id = location.pathname.replace(/\//g,'')||'home'
                $('body').attr('id', page.id);
                $('main').animate({ opacity : 1 }, 'fast');
                pageLoadedInit(page);
              });
    }

	//flat-height
	$('.item-list').on('flatHeight',function(){
		var target = this;
		this.maxHeight = 0;
		$(this).find('a').each(function(){
			var height = $(this).height();
			if(target.maxHeight < height) target.maxHeight = height;
		});
		$(this).find('a').height(this.maxHeight);
	});
	$(window).on('resize',function(){
		$('.item-list').trigger('flatHeight');
	});

    pageLoadedInit(page);

});//end jQuery

function pageLoadedInit(page){

	//flat-height
	$('.item-list').each(function(){
		var target = this;
		var $img = $(this).find('img');
		if($img.length){
			$img.bind("load", function(){
				$(target).trigger('flatHeight');
			});
		}else{
			$(target).trigger('flatHeight');
		}
	});

	//新規ウィンドウ
	var noBlank	= [
		'[href^="http://new-shop.lc/"]',
		'[href^="http://newshop-hmmt.com/"]',
		'[href^="https://newshop-hmmt.com/"]',
		'[href^="http://test.newshop-hmmt.com"]'
	];
	$('a[href^="https://"]:not('+noBlank+'),a[href^="http://"]:not('+noBlank+')').blankWindow();
	$('a[href$="\.pdf"]').blankWindow('pdfLink');

}
