import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import googleCalendarPlugin from '@fullcalendar/google-calendar';

document.addEventListener('DOMContentLoaded', function() {
  const calendarEl = document.getElementById('calendar');
  if(!calendarEl) return this;

  let calendar = new Calendar(calendarEl, {
    plugins: [ dayGridPlugin, googleCalendarPlugin ],
    // defaultView: 'timeGridWeek',
    googleCalendarApiKey: "AIzaSyCzDj3dJ6go9Y5lreCiovkoRHrBU4aAn2U",
    height: 600,
    header:{
      left:"title",
      center:"",
      right:"prev,next today"
    },
    allDaySlot: false,
    allDayText:"",
    locale:"ja",
    buttonText: {
      today:    '今日',
      month:    '月',
      week:     '週',
      day:      '日',
      list:     'リスト'
    },
    eventClick: function(info){
      info.jsEvent.preventDefault();
      return false;
    },
    eventSources: [
      {
        name:'営業日',
        className:'open-date',
        googleCalendarId: 'ek3qgbh43gcdj6g5t8vbf06pl0@group.calendar.google.com',
        //old calendar API v2//feed:'https://www.google.com/calendar/feeds/ek3qgbh43gcdj6g5t8vbf06pl0@group.calendar.google.com/public/basic',
        backgroundColor:'#552111',//Newshop Official Brown
        textColor:'#ffffff'
      },{
        name:'イベント',
        className:'event',
        googleCalendarId: '10jcc7tiqcu3kf10m7e58squ9g@group.calendar.google.com',
        //old calendar API v2//feed:'https://www.google.com/calendar/feeds/10jcc7tiqcu3kf10m7e58squ9g@group.calendar.google.com/public/basic',
        backgroundColor:'#0d3a50',//navy
        textColor:'#ffffff'
      },{
        name:'休日',
        className:'holiday',
        googleCalendarId: 'japanese__ja@holiday.calendar.google.com',
        //old calendar API v2//feed:'http://www.google.com/calendar/feeds/ja.japanese%23holiday%40group.v.calendar.google.com/public/basic',
        backgroundColor:'#f09abb',//$Pink
        textColor:'#ffffff',
        isCache:true
      }
    ],
  });

  calendar.render();
});
